import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import BaseLayout from '../components/layout/base-layout';
import CatalogHeader from '../components/common/catalog-header';
import PageColumn from '../components/layout/page-column';
import RainbowBar from '../components/common/rainbow-bar';
import Section from '../components/layout/section';
import TaxonomyGrid from '../components/common/taxonomy/grid';

export default (props) => {
  let categoryNodes = props.data.categories.edges.map(({ node }) => node);
  categoryNodes = categoryNodes.filter(category => {
    let bookNodes = category.data.Books || [];
    const publishedBookNodes = bookNodes.filter(book => book.data.Published);

    return publishedBookNodes.length > 0;
  });

  return (
    <BaseLayout>
      <Helmet>
        <title>Browse Cookbooks by Category</title>
        <meta
          property="og:title"
          content="Browse Cookbooks by Category"
        />
      </Helmet>
      <CatalogHeader
        label="Browse Cookbooks by Category"
      />
      <RainbowBar width={'thin'} />
      <Section topSpacing="section" bottomSpacing="section">
        <PageColumn>
          <TaxonomyGrid
            nodes={ categoryNodes }
            labelProperty="Label"
            pathStem="/category"
          />
        </PageColumn>
      </Section>
    </BaseLayout>
  );
};

export const query = graphql`
{
  categories:allAirtable(filter:{table:{eq:"Categories"}},
                  sort:{fields: data___Label, order:ASC}) {
    edges {
      node {
        data {
          Label
          Slug
          Books {
            data {
              Slug
              Published
            }
          }
        }
      }
    }
  }
}
`;
